import {
  BuildingStorefrontIcon,
  ClipboardDocumentCheckIcon,
  DocumentChartBarIcon,
  DocumentIcon,
  EnvelopeIcon,
  FolderIcon,
  PresentationChartBarIcon,
  QueueListIcon,
  RocketLaunchIcon,
  TrophyIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";

export const userListNavigation = [
  {
    name: "Create New User",
    href: "/admin/user/",
    icon: UsersIcon,
  },
  {
    name: "All Users",
    href: "/admin/users/all",
    icon: UsersIcon,
  },
  {
    name: "Portfolio Users",
    href: "/admin/users/portfolio",
    icon: UsersIcon,
  },
  {
    name: "Sponsor Users",
    href: "/admin/users/sponsor",
    icon: UsersIcon,
  },
  {
    name: "Investor Users",
    href: "/admin/users/investor",
    icon: UsersIcon,
  },
  {
    name: "Ecosystem Users",
    href: "/admin/users/ecosystem",
    icon: UserGroupIcon,
  },
  {
    name: "Video Categorization",
    href: "/admin/videos",
    icon: VideoCameraIcon,
    current: false,
  },
  {
    name: "Portfolio Video List",
    href: "/admin/portfolio/videos",
    icon: VideoCameraIcon,
    current: false,
  },
  {
    name: "Admin Reports",
    href: "/admin/reports",
    icon: DocumentIcon,
  },
];

export const applicationUtilityNavigation = [
  {
    name: "User Access Requests",
    href: "/admin/access-requests",
    icon: UsersIcon,
  },
  {
    name: "Confidential Files",
    href: "/admin/confidential-files",
    icon: FolderIcon,
  },
  {
    name: "Nominations",
    href: "/admin/nominations",
    icon: TrophyIcon,
  },
  {
    name: "List Management",
    href: "/admin/list-management",
    icon: QueueListIcon,
  },
  {
    name: "Reviewer Users",
    href: "/admin/reviewer-users",
    icon: UserCircleIcon,
    current: false,
  },
  {
    name: "Reviews by Program",
    href: "/admin/reviewer-companies",
    icon: BuildingStorefrontIcon,
  },
  {
    name: "Curation Totals",
    href: "/admin/partner-reviews",
    icon: ClipboardDocumentCheckIcon,
  },
];

export const adminNavigation = [
  {
    name: "Airtable PortCo Sync",
    href: "/admin/users/airtable-portfolio",
    icon: UsersIcon,
  },

  {
    name: "Company Diligence",
    href: "/admin/diligence",
    icon: DocumentChartBarIcon,
  },

  {
    name: "Video Categorization",
    href: "/admin/videos",
    icon: VideoCameraIcon,
  },
  {
    name: "Portfolio Video List",
    href: "/admin/portfolio/videos",
    icon: VideoCameraIcon,
  },
  {
    name: "Admin Reports",
    href: "/admin/reports",
    icon: DocumentIcon,
  },

  {
    name: "Marketing",
    href: "/admin/marketing",
    icon: FolderIcon,
  },
  {
    name: "Pitchbook CSV Import",
    href: "/admin/pitchbook",
    icon: DocumentChartBarIcon,
  },
  {
    name: "Event Management",
    href: "/events/all",
    icon: PresentationChartBarIcon,
  },
  {
    name: "Sent Email Statistics",
    href: "/admin/email-statistics",
    icon: EnvelopeIcon,
  },
  {
    name: "Blast Sender - MTI 2025",
    href: "https://medtechinnovator.org/wp-login.php?redirect_to=laravel/email/list/2025",
    icon: RocketLaunchIcon,
  },
  {
    name: "Blast Sender - APAC 2025",
    href: "https://medtechinnovator.org/laravel/email/list/asia2025",
    icon: RocketLaunchIcon,
  },
  {
    name: "Blast Sender - BTI 2025",
    href: "https://medtechinnovator.org/laravel/email/list/biotools2025",
    icon: RocketLaunchIcon,
  },
  {
    name: "Blast Sender - MTI 2024",
    href: "https://medtechinnovator.org/laravel/email/list/2024",
    icon: RocketLaunchIcon,
  },
  {
    name: "Blast Sender - APAC 2024",
    href: "https://medtechinnovator.org/laravel/email/list/asia2024",
    icon: RocketLaunchIcon,
  },
  {
    name: "Blast Sender - BTI 2024",
    href: "https://medtechinnovator.org/laravel/email/list/biotools2024",
    icon: RocketLaunchIcon,
  },
  {
    name: "Additional Information to Email to Applicants",
    href: "/admin/email-applicants",
    icon: RocketLaunchIcon,
  },
];
